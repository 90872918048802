/**
 * OOI-specific experiments should be listed here.
 * scope: viewer-apps-14bcded7-0066-7c35-14d7-466cb3f09103
 */
export const OOI_EXPERIMENTS = {
  /**
   * https://bo.wix.com/guineapig/experiments/3508196
   */
  INSTALL_SEARCH: 'specs.wixBlog.InstallSearch',
  /**
   * https://bo.wix.com/guineapig/experiments/3627662
   */
  USE_PLATFORM_API: 'specs.wixBlog.migratedToNewPlatformApi',
  /** https://bo.wix.com/guineapig/experiments/2999137 */
  MANAGE_POSTS_NAVIGATE_TO_POSTS: 'specs.wixBlog.ManagePostsNavigateToPosts',
  /** https://bo.wix.com/guineapig/experiments/3727397 */
  SLOTS_IN_POST_PAGE: 'specs.wixBlog.SlotsInPostPage',
  /**
   * https://bo.wix.com/guineapig/experiments/3627662
   */
  SSR_COMMENTS: 'specs.wixBlog.SSRComments',
};
